@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.app {
    min-height: 100vh;
}

.landing-page {
    position: relative;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: white;
    padding: 2rem;
}

.title {
    font-size: 4rem;
    font-weight: 700;
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.tagline {
    font-size: 1.5rem;
    margin: 1rem 0 0;
    font-family: Georgia, 'Times New Roman', serif;
    font-style: italic;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
    .title {
        font-size: 2.5rem;
    }

    .tagline {
        font-size: 1.2rem;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 2rem;
    }

    .tagline {
        font-size: 1rem;
    }
}